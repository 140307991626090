import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Polyline,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import config from "../config";
import { VideoMarker } from "../types";

function renderMap(props: any) {
  let map: GoogleMap;
  let markers: VideoMarker[] = props.markers;
  const last = props.locations && props.locations[props.locations.length - 1];

  return (
    <GoogleMap
      ref={(r: any) => {
        map = r;
        props.onMapLoaded(map);
      }}
      defaultZoom={17}
      defaultCenter={{ lat: -34.397, lng: 150.644 }}
      defaultOptions={{
        draggable: true,
        scrollwheel: false,
        streetViewControl: true,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
      }}
      zoom={props.zoom || 17}
    >
      {!!props.initialPosition && (
        <Marker
          key="first"
          icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          position={props.initialPosition.position}
        />
      )}
      {markers.map((marker, i) => (
        <Marker key={i} {...marker} />
      ))}
      {!!last && <Marker key="last" position={last.location} />}
      {props.initialPosition && props.locations && (
        <Polyline
          path={[
            props.initialPosition.position,
            ...props.locations.map(({ location }: any) => location),
          ]}
        />
      )}
    </GoogleMap>
  );
}

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleClientKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ width: "100%", height: "400px" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(renderMap);
