import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "underscore";
import { ppApiService } from "../../services/pp-api-service";
import Download from "./download";
import VideoContainer from "./video-container";

interface State {
  incident: any;
  videos: any[];
  error: any;
}

class Incident extends React.Component<
  RouteComponentProps,
  State
> {
  incidentId: string;
  pollInterval = 2000;
  pollTimeout: any = 0;
  pollSince = 0;
  token: string;

  constructor(props: RouteComponentProps) {
    super(props);
    this.incidentId = "";
    this.token = "";

    this.state = {
      incident: null,
      videos: [],
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.location.pathname.startsWith('/incidents/a0/')) {
      this.token = (this.props.match.params as any).token;
      this.fetchIncidentByToken();
    }else{
      this.incidentId = (this.props.match.params as any).id;
      this.fetchIncident();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.pollTimeout);
  }

  render() {
    var { incident, videos, error } = this.state;

    if(error){
      return(
        <div className="column row text-center">
          <br />
          <div className="callout success" style={{position: "absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <strong>{error.error}</strong>
          </div>
        </div>
      );
    }

    // if the incident data is not loaded yet, show a loading message
    if (!incident) {
      return (
        <div className="column row text-center">
          <br />
          <div className="callout success">
            <i className="fa fa-spinner fa-spin"></i>{" "}
            <strong>Fetching incident ...</strong>
          </div>
        </div>
      );
    }

    // Get rid of all characters from the teltag link
    var telTagPhone = incident.providerPhone.replace(/\D/g, "");
    var createdAt = moment(this.state.incident.createdAt).format("LLL");
    return (
      <div>
        <VideoContainer videos={videos} incident={incident} />
        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-fw fa-bell"></i> Alerting Company Details
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-xs-12 col-sm-8">
                <h3 style={{ marginTop: 0 }}>
                  <strong>{incident.providerName}</strong>
                </h3>
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-fw fa-code"></i>&nbsp;{" "}
                    <strong>Incident Code:</strong> {incident.incidentCode}
                  </li>
                  <li>
                    <i className="fa fa-fw fa-hourglass-start"></i>&nbsp;{" "}
                    <strong>Created: </strong>
                    {createdAt}
                  </li>
                </ul>
              </div>
              <div className="col-xs-12 col-sm-4">
                <p>
                  <a href={"tel:" + telTagPhone} className="btn btn-primary">
                    <i className="fa fa-phone"></i> Call {incident.providerName}
                  </a>
                </p>
                <p>
                  Phone Number:{" "}
                  <a href={"tel:" + telTagPhone}>{incident.providerPhone}</a>
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mt-4 mb-4">
          <Card.Header>
            <i className="fa fa-comments-o"></i> Comments
          </Card.Header>
          <Card.Body>
            {videos.map((v, i) => {
              if (v.comments) {
                return (
                  <div className="row" key={i}>
                    <div className="col-xs-12">
                      <p>
                        <strong>
                          {moment(v.updatedAt).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                          )}
                          :{" "}
                        </strong>
                        {v.comments}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </Card.Body>
        </Card>

        <Download incident={incident} videos={videos} />
      </div>
    );
  }

  fetchIncident() {
    ppApiService
      .getIncident(this.incidentId)
      .then((incident) => {
        document.getElementById("provider_logo").style.display = "block"
        if (incident.providerLogo == "") {
          document.getElementById("provider_logo").setAttribute("src", "https://staticfil.s3.ap-south-1.amazonaws.com/TA-IncidentCo+-+Logo+Color+Concept+v2.jpg")
        } else {
          document.getElementById("provider_logo").setAttribute("src", incident.providerLogo)
          // document.getElementById("provider_logo").setAttribute("src", "https://staticfil.s3.ap-south-1.amazonaws.com/TA-IncidentCo+-+Logo+Color+Concept+v2.jpg")
        }
        incident.regions = incident.regions.map((region: any) => {
          region.path = JSON.parse(region.path);
          return region;
        });

        this.fetchIncidentVideos();
        this.setState({
          incident: incident,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
      });
  }

  fetchIncidentVideos = () => {
    ppApiService
      .getIncidentVideos(this.incidentId, this.pollSince)
      .then((data) => {
        var videos = [...this.state.videos];

        data.videos.forEach((video: any, i: number) => {
          var foundIndex = _.findIndex(videos, { id: video.id });
          var existingVideo = videos[foundIndex];

          // if we already have the video, if its been disapproved then remove it
          if (existingVideo) {
            if (video.approved == false) {
              videos.splice(foundIndex, 1);
            } else {
              videos[i] = video;
            }
          } else if (video.approved) {
            videos.push(video);
          }
        });

        // sort by created
        videos = _.chain(videos)
          .sortBy("createdAt")
          .sortBy("id")
          .value()
          .reverse();

        this.setState({
          videos: videos,
        });

        this.pollSince = data.since;
        this.pollTimeout = setTimeout(
          this.fetchIncidentVideos,
          this.pollInterval
        );
      })
      .catch((error) => {
        console.error("PPIncident:fetchIncidentVideos", error);
      });
  };

  fetchIncidentByToken() {
    ppApiService
      .getIncidentByToken(this.token)
      .then((incident) => {
        incident.regions = incident.regions.map((region: any) => {
          region.path = JSON.parse(region.path);
          return region;
        });
        this.incidentId = incident.id.toString();
        this.fetchIncidentVideos();
        this.setState({
          incident: incident,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
      });
  }
}

export default withRouter(Incident)