import { Incident, IncidentVideo, Config, QueryResult, IncidentLocation } from "incident-code-core"
import config from "../config"
import { ApiService } from "./api-service"

class PPICApiService extends ApiService {
  baseUrl = config.ppicApi.url
  config: Config

  async init(): Promise<void> {
    if (this.config == null) {
      this.config = await this.getConfig()
    }

    return Promise.resolve()
  }

  lookup(code: string): Promise<Incident> {
      // TODO: support other country
      code =  code

    return this.get(["incidents", "code", code])
  }

  getConfig(): Promise<Config> {
    return this.get(["configs"]).then(config => {
      return Object.assign(new Config(), config)
    })
  }

  getIncident(id: string): Promise<Incident> {
    return this.get(["incidents", id])
  }

  getIncidentVideos(id: string): Promise<QueryResult<IncidentVideo>> {
    return this.get(["incidents", id, "videos"])
  }

  getIncidentLocations(id: any): any {
    return this.get(["incidents", id, 'location'])
  }

  getChatHistory(id: any): any {
    return this.get(["wall", "public", "history", id])
  }

  protected addHeaders(request: Request, contentType: string) {
    super.addHeaders(request, contentType)
    request.headers.set("Security-Code", config.ppicApi.securityCode)
    request.headers.set("Client-Version", config.appVersion)
  }
}

export const ppicApiService = new PPICApiService()
