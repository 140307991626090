import React from "react";
import MapView from "../map";
import _ from "underscore";
import { GoogleMap } from "react-google-maps";

interface Props {
  incident: any;
  markers: any[];
}

interface State {
  incident: any;
  videos: any[];
  activeVideo: any;
  activeVideoIndex: any;
}

export default class IncidentMap extends React.Component<Props, State> {
  map: GoogleMap;
  zoom: number;

  constructor(props: Props) {
    super(props);
  }

  componentWillUnmount() {
    this.map = null;
  }

  onMapLoaded = (map: any) => {
    if (map) {
      this.map = map;
      this.centerMap();
    }
  };

  centerMap() {
    var { incident } = this.props;
    if (incident.regions && incident.regions.length > 0) {
      this.zoomToRegions();
    } else if (incident.locationLat && incident.locationLng) {
      this.zoomToLocation();
    } else {
      this.zoomToAddress();
    }
  }

  zoomToRegions() {
    var { regions } = this.props.incident;
    var bounds = new google.maps.LatLngBounds();
    _.each(regions, (region: any) => {
      _.each(region.path, (path: any) => {
        bounds.extend(new google.maps.LatLng(path.lat, path.lng));
      });
    });

    this.map.fitBounds(bounds);
  }

  zoomToLocation() {
    var { incident } = this.props;
    this.zoom = incident.locationZoomLevel || 17;
    this.map.panTo(
      new google.maps.LatLng(incident.locationLat, incident.locationLng)
    );
  }

  zoomToAddress() {
    var { locationAddress } = this.props.incident;
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: locationAddress }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK && results[0]) {
        this.map.panTo(results[0].geometry.location);
        this.zoom = 18;
      }
    });
  }

  setZoom() {
    return this.zoom;
  }

  render() {
    return (
      <MapView
        onMapLoaded={this.onMapLoaded}
        markers={this.props.markers}
        zoom={this.setZoom()}
      />
    );
  }
}
