import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Route, RouteComponentProps, Switch } from "react-router-dom";
import { logPageView } from "../utils/ga";
import Entry from "./entry";
import PPIncident from "./pp-incident/incident";
import PPICIncident from "./ppic-incident/incident";
import Terms from "./terms";

export default class App extends React.Component<RouteComponentProps> {
	constructor(props: RouteComponentProps) {
		super(props);

		logPageView(this.props.location)
		this.props.history.listen((location) => {
			logPageView(location)
		});
	}

	componentDidMount(){
		if (location.hash){
			this.props.history.replace(location.hash.replace("#",""))
		}
	}

	render() {
		return (
			<>
				<Navbar expand="md" bg="dark" variant="dark" fixed="top">
					<Container>
						<Link to="/" className="navbar-brand">IncidentCODE</Link>
						<span style={{color: "white"}}>Powered By</span>
						<img height="25" width="100" style={{marginLeft: "10px", display:"none"}} id= "provider_logo" src="" alt="Logo" />;
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse>
							<Nav className="ml-auto">
								<Nav.Item><Link to="/terms" className="nav-link">Terms of Service</Link></Nav.Item>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>

				<Container>
					<Switch>
						<Route path="/terms" component={Terms} />
						<Route exact path="/incidents/:id" component={PPIncident}  />
						<Route exact path="/incidents/a0/:token" component={PPIncident}  />
						<Route path="/incident/:id" component={PPICIncident}  />
						<Route path="/" component={Entry} />
					</Switch>
				</Container>
			</>
		)
	}
}