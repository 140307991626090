import { ApiService } from "./api-service"
import config from "../config"

class PPApiService extends ApiService {
  baseUrl = config.ppApi.url

  constructor(){
    super()
    console.log("hi",this.baseUrl)
  }
  
  lookup(code: string): Promise<any> {
    return this.get(["lookup", code])
  }

  getIncident(id: string): Promise<any> {
    return this.get(["incidents", id])
  }

  getIncidentVideos(id: string, since: number): Promise<any> {
    return this.get(["incidents", id, "videos", since.toString()])
  }

  getIncidentByToken(token: string): Promise<any> {
    return this.get(["incident", "token", token])
  }
}

export const ppApiService = new PPApiService()
